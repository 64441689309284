import { Typography } from "@mui/material";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import IndexJsonLd from "../components/IndexjsonLd";
import LoadingCircular from "../components/loadingCircular";
import topBackground from "../images/background.png";
import { default as Seo } from "../layouts/head";
import Layout from "../layouts/pc";
import LayoutSP from "../layouts/sp";

export const Terms = () => {
  const [loading, setLoading] = useState(true);
  const title = "利用規約";
  useEffect(() => {
    setTimeout(() => setLoading(false), 50);
  }, []);
  const breakpoints = useBreakpoint();
  return (
    <>
      <Seo title={title} />
      <IndexJsonLd title={title} />
      {loading ? (
        <LoadingCircular />
      ) : breakpoints.sm ? (
        <LayoutSP>
          <MainDiv>
            <TopDiv>
              <MainTitle>利用規約</MainTitle>
              <Text>
                本ウェブサイトはLagopus（以下「当方」といいます）が運営しております。
                本ウェブサイトを利用される前に以下の利用条件をお読みいただき、これらの条件に同意された場合のみご利用ください。本ウェブサイトをご利用されることにより、以下の条件に同意されたものとみなします。なお、以下の条件は、予告なしに変更されることがあります。本条件が変更された場合、変更後の利用条件に従っていただきます。あらかじめご了承ください。
              </Text>
              <SecondTitle>1. 著作権について</SecondTitle>
              <Text>
                本ウェブサイト上のすべてのコンテンツに関する著作権は、特段の表示のない限り当方に帰属しております。そのすべてまたは一部を、法律にて定められる私的使用等の範囲を超えて、無断で複製、転用、改変、公衆送信、販売などの行為を行うことはできません。
              </Text>
              <SecondTitle>2. 商標について</SecondTitle>
              <Text>
                本ウェブサイトで使用されている商標・ロゴマーク・商号は、当方の登録商標または商標です。商標法またはその他の法律により認められている場合を除き、当方の事前の承諾なしに、これらを使用等することはできません。
              </Text>
              <SecondTitle>3. 免責事項</SecondTitle>
              <Text>
                当方は、本ウェブサイトに掲載されている内容について、その正確性、有用性、確実性について保証するものではなく、一切の責任を負わないものといたします。
                当社は、予告なしに、本ウェブサイトの運営を中断または中止、掲載内容を修正、変更、削除する場合がありますが、それらによって生じるいかなる損害についても一切責任を負いません。また本ウェブサイトのご利用によりお客様または第三者のハードウェアおよびソフトウェア上に生じた事故、データの毀損・滅失等の損害について一切責任を負いません。
              </Text>
              <SecondTitle>4. リンクについて</SecondTitle>
              <Text>
                営利、非営利、イントラネットを問わず、本ウェブサイトへのリンクは自由です。
                ただし、公序良俗に反するサイトなど、当社の信用、品位を損なうサイトからのリンクはお断りします。また事前事後にかかわらず、その他の理由によりリンクをお断りする場合もあります。
              </Text>
              <SecondTitle>5. 禁止事項</SecondTitle>
              <Text>
                本サービスの利用にあたり，以下の行為をしてはなりません
              </Text>
              <ol>
                <li>法令または公序良俗に違反する行為</li>
                <li>犯罪行為に関連する行為</li>
                <li>
                  本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
                </li>
                <li>
                  当方，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
                </li>
                <li>本サービスによって得られた情報を商業的に利用する行為</li>
                <li>当方のサービスの運営を妨害するおそれのある行為</li>
                <li>不正アクセスをし，またはこれを試みる行為</li>
                <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
                <li>不正な目的を持って本サービスを利用する行為</li>
                <li>
                  当方が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
                </li>
                <li>
                  当方のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
                </li>
                <li>その他，当方が不適切と判断する行為</li>
              </ol>
              <SecondTitle>6.本サービスの提供の停止等</SecondTitle>
              <Text>
                <ol>
                  <li>
                    当方は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                  </li>
                  <li>
                    本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                  </li>
                  <li>
                    地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
                  </li>
                  <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                  <li>その他，当社が本サービスの提供が困難と判断した場合</li>
                  <li>
                    当方は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
                  </li>
                </ol>
              </Text>
            </TopDiv>
          </MainDiv>
        </LayoutSP>
      ) : (
        <Layout>
          <MainDiv>
            <TopDiv>
              <MainTitle>利用規約</MainTitle>
              <Text>
                本ウェブサイトはLagopus（以下「当方」といいます）が運営しております。
                本ウェブサイトを利用される前に以下の利用条件をお読みいただき、これらの条件に同意された場合のみご利用ください。本ウェブサイトをご利用されることにより、以下の条件に同意されたものとみなします。なお、以下の条件は、予告なしに変更されることがあります。本条件が変更された場合、変更後の利用条件に従っていただきます。あらかじめご了承ください。
              </Text>
              <SecondTitle>1. 著作権について</SecondTitle>
              <Text>
                本ウェブサイト上のすべてのコンテンツに関する著作権は、特段の表示のない限り当方に帰属しております。そのすべてまたは一部を、法律にて定められる私的使用等の範囲を超えて、無断で複製、転用、改変、公衆送信、販売などの行為を行うことはできません。
              </Text>
              <SecondTitle>2. 商標について</SecondTitle>
              <Text>
                本ウェブサイトで使用されている商標・ロゴマーク・商号は、当方の登録商標または商標です。商標法またはその他の法律により認められている場合を除き、当方の事前の承諾なしに、これらを使用等することはできません。
              </Text>
              <SecondTitle>3. 免責事項</SecondTitle>
              <Text>
                当方は、本ウェブサイトに掲載されている内容について、その正確性、有用性、確実性について保証するものではなく、一切の責任を負わないものといたします。
                当社は、予告なしに、本ウェブサイトの運営を中断または中止、掲載内容を修正、変更、削除する場合がありますが、それらによって生じるいかなる損害についても一切責任を負いません。また本ウェブサイトのご利用によりお客様または第三者のハードウェアおよびソフトウェア上に生じた事故、データの毀損・滅失等の損害について一切責任を負いません。
              </Text>
              <SecondTitle>4. リンクについて</SecondTitle>
              <Text>
                営利、非営利、イントラネットを問わず、本ウェブサイトへのリンクは自由です。
                ただし、公序良俗に反するサイトなど、当社の信用、品位を損なうサイトからのリンクはお断りします。また事前事後にかかわらず、その他の理由によりリンクをお断りする場合もあります。
              </Text>
              <SecondTitle>5. 禁止事項</SecondTitle>
              <Text>
                本サービスの利用にあたり，以下の行為をしてはなりません
              </Text>
              <ol>
                <li>法令または公序良俗に違反する行為</li>
                <li>犯罪行為に関連する行為</li>
                <li>
                  本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
                </li>
                <li>
                  当方，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
                </li>
                <li>本サービスによって得られた情報を商業的に利用する行為</li>
                <li>当方のサービスの運営を妨害するおそれのある行為</li>
                <li>不正アクセスをし，またはこれを試みる行為</li>
                <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
                <li>不正な目的を持って本サービスを利用する行為</li>
                <li>
                  当方が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
                </li>
                <li>
                  当方のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
                </li>
                <li>その他，当方が不適切と判断する行為</li>
              </ol>
              <SecondTitle>6.本サービスの提供の停止等</SecondTitle>
              <Text>
                <ol>
                  <li>
                    当方は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                  </li>
                  <li>
                    本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                  </li>
                  <li>
                    地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
                  </li>
                  <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                  <li>その他，当社が本サービスの提供が困難と判断した場合</li>
                  <li>
                    当方は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
                  </li>
                </ol>
              </Text>
            </TopDiv>
          </MainDiv>
        </Layout>
      )}
    </>
  );
};

const MainDiv = styled.div`
  ${({ theme }) => css`
    ${theme.typography};
    background-image: url(${topBackground});
  `}
`;

const TopDiv = styled.div`
  max-width: 1240px;
  padding: 50px;
  margin: 0 auto;
`;

const MainTitle = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl2};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    margin-bottom: 20px;
  `}
`;

const SecondTitle = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    margin: 20px 0;
  `}
`;

const Text = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.base};
    line-height: 24px;
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
  `}
`;

export default Terms;
